import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
function SEO(props) {
  const { title, ltd_data } = props;
  /*ltd_data {
        ltdDataProductImage {
          asset {
            id
          }
        }
        ltdDataProductName
        ltdDataProductPrice
        ltdDataProductUrl
        ltdDataProductCurrency
        ltdDataProductDescription
      } */
  return (
    <Helmet title={title ? `${title} | Bonitáska` : "Bonitáska"}>
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type": "Product",
          "name": "${ltd_data.ltdDataProductName}",
          "image": "${ ltd_data.ltdDataProductImage ? imageUrlFor(buildImageObj(ltd_data.ltdDataProductImage))
            .width(1200)
            .height(Math.floor((9 / 16) * 1000))
            .fit("crop")
            .url():''}",
          "description": "${ltd_data.ltdDataProductDescription}",
          "sku": "${ltd_data.ltdDataProductUrl}",
          "brand": {
            "@type": "Thing",
            "name": "Bonitáska"
          },
          "offers": {
            "@type": "Offer",
            "priceCurrency": "${ltd_data.ltdDataProductCurrency}",
            "price": "${ltd_data.ltdDataProductPrice}",
            "itemCondition": "http://schema.org/NewCondition",
            "availability": "http://schema.org/InStock"
          }
        }
        `}
      </script>
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  ltd_data: PropTypes.object,
};
SEO.defaultProps = {
  title: null,
  ltd_data: {},
};

export default SEO;
