import { Link } from "gatsby";
import React from "react";
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { cn } from "../lib/helpers";
import { FaHeart } from "react-icons/fa";
import * as styles from "./header.module.css";
import { useItemContext } from "../context/item/context";



const Header = (props) => {
  const { onHideNav, onShowNav, showNav, logo } = props
  const { state } = useItemContext();
  const [favorites, setFavorites] = React.useState([]);
  React.useEffect(() => {
    setFavorites(state.item);
  }, [state]);
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.branding}>
        <Link to={"/"}><img
                  src={imageUrlFor(buildImageObj(logo))
                    .width(100)
                    .height(Math.floor((9 / 16) * 100))
                    .fit("crop")
                    .format("webp")
                    .url()}
                  alt={logo.alt}
                /></Link>
        </div>

        <label htmlFor="check" className={styles.burger}>
          <input
            type="checkbox"
            id="check"
            onClick={showNav ? onHideNav : onShowNav}
            onChange={showNav ? onHideNav : onShowNav}
            checked={showNav}
          />
          <span></span>
          <span></span>
          <span></span>
        </label>
        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            <li>
              <Link to="/rolunk/" activeClassName={styles.active}>
                Rólunk
              </Link>
            </li>
            <li>
              <Link to="/taskak/" activeClassName={styles.active}>
                Összes termék
              </Link>
            </li>
            <li>
              <Link to="/kategoriak/" activeClassName={styles.active}>
                Kategóriák
              </Link>
            </li>
            <li>
              <Link to="/kapcsolat/" activeClassName={styles.active}>
                Kapcsolat
              </Link>
            </li>
            <li>
              <Link to="/kedvencek" activeClassName={styles.active}>
                <sup>{favorites.length}</sup>
                <FaHeart />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
